<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M25.8391 6.28281C26.1389 6.58355 26.2161 7.04081 26.0316 7.42326L16.8791 26.3973C16.6957 26.7775 16.2932 27.0016 15.8733 26.9572C15.4535 26.9129 15.1067 26.6096 15.0068 26.1994L13.2163 18.8453L5.77374 17.1163C5.36003 17.0202 5.05217 16.6732 5.00596 16.251C4.95976 15.8288 5.1853 15.4235 5.56842 15.2401L24.6993 6.08674C25.0823 5.90347 25.5393 5.98208 25.8391 6.28281ZM9.00407 15.8135L14.2686 17.0365C14.6363 17.1219 14.9246 17.4071 15.0139 17.774L16.29 23.0152L22.9923 9.1206L9.00407 15.8135Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconNearMeOff",
});
</script>
